body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

root {
	width: 100%;
}
/* @media only screen and (max-width: 650px) {
  label[data-shrink="false"] {
    display: none;
  }
} */

li.MuiListItem-root .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
	min-width: 127px;
	padding: 6px;
}

.MuiListItem-root.multiLevel.MuiListItem-gutters {
	align-items: initial;
}

.introList .MuiSvgIcon-root {
	font-size: 1rem;
}
